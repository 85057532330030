(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/image.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/image.js');
'use strict';

const {
  useState,
  useEffect
} = React;
const {
  clsvs
} = svs.utils;
const {
  getNoscriptUrl,
  getSourceElementsData,
  getUrlWithCorrectHost
} = Handlebars.helpers;
const config = {
  options: svs.core.config.data.svsconfig
};
const logger = new svs.core.log.Logger('image-v2:React_Image');
const Image = _ref => {
  let {
    image,
    cssSelectors,
    onLoadCallback,
    fallbackImage
  } = _ref;
  const {
    altText,
    aspectRatio,
    dominantColor,
    fitWrapper,
    originalImage,
    loadingLazy,
    noscriptUrl,
    sizes,
    title
  } = image || {};
  const [hasError, setHasError] = useState(false);
  const fallbackImageUrl = getUrlWithCorrectHost(fallbackImage, config);
  const sourceElements = getSourceElementsData(image);
  const noScriptUrl = getNoscriptUrl(image, undefined, noscriptUrl, false);
  const imageClassName = clsvs('image-wrapper', fitWrapper && 'background-image-wrapper image-wrapper-fit', cssSelectors);

  useEffect(() => {
    setHasError(false);
  }, [noScriptUrl]);
  const imageStyle = {
    backgroundColor: dominantColor || null
  };
  const imageType = 'http://schema.org/ImageObject';
  const handleError = ev => {
    const {
      currentTarget
    } = ev;
    currentTarget.onerror = null;
    if (fallbackImage) {
      setHasError(true);
    } else {
      logger.warn("Failed to load image: ".concat(currentTarget.currentSrc));
    }
  };
  const Img = () => {
    const ImgSrc = hasError ? fallbackImageUrl : noScriptUrl;
    return React.createElement("img", {
      alt: altText || title || '',
      className: loadingLazy ? 'js-image image lazyload' : 'js-image image',
      "data-fallback-image": hasError,
      "data-src": loadingLazy ? ImgSrc : null,
      decoding: "sync",
      height: aspectRatio === null || aspectRatio === void 0 ? void 0 : aspectRatio.height,
      itemProp: "image",
      onError: handleError,
      onLoad: onLoadCallback,
      sizes: sizes || '100vw',
      src: !loadingLazy ? ImgSrc : null,
      title: title || null,
      width: aspectRatio === null || aspectRatio === void 0 ? void 0 : aspectRatio.width
    });
  };
  return React.createElement("div", {
    className: imageClassName,
    "data-is-react": true,
    itemScope: true,
    itemType: imageType,
    style: imageStyle
  }, React.createElement("meta", {
    content: hasError ? fallbackImageUrl : noScriptUrl,
    itemProp: "url"
  }), originalImage || hasError ? React.createElement(Img, null) : React.createElement("picture", null, sourceElements.map(source => React.createElement("source", {
    "data-src": source.dataSrc,
    key: source.id,
    media: source.media,
    sizes: source.sizes,
    srcSet: source.srcSet,
    type: source.type
  })), React.createElement(Img, null)));
};
svs.content.image_v2.Image = Image;

 })(window);